<template>
  <v-dialog v-model="visible" max-width="750">
    <template #activator="{on, attrs}">
      <v-textarea
          readonly
          v-model="getPlaceholder"
          :label.html="getLabel"
          v-bind="attrs"
          v-on="on"
          auto-grow
          row-height="6"
          no-resize
          :clearable="clearable"
          :rules="[v => (!!v || (!v && clearable)) || 'Выберите элемент']"
      />
    </template>
    <v-card>
      <v-card-title v-html="`Выберите&nbsp;<u>${getLabel.toLowerCase()}</u>`"/>
      <v-text-field class="mx-6" label="Поиск" v-model="search"/>
      <v-card-text>
        <v-treeview
          :items="getTree"
          :search="search"
          class="py-3"
          hoverable
          open-on-click
          dense
        >
          <template #append="{ item }">
            <v-btn
              @click="selectCatalog(item.id)"
              small
              depressed
              color="primary"
            >
              Выбрать
            </v-btn>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  TYPE_CATEGORY: 'category',
  TYPE_STRUCTURE: 'structure',
  TYPE_DIRECTION: 'direction',

  name: "CatalogSelector",
  props: ['value', 'type', 'clearable'],

  data: () => ({
    visible: false,
    search: null,
  }),

  mounted() {
    this.actionFetchData()
  },

  computed: {
    ...mapGetters('stock_categories', [
        'getStockRootCategories', 'getStockCategoryAncestorsById', 'getStockCategoryById'
    ]),
    ...mapGetters('stock_tree', [
        'getStockRootTree', 'getStockTreeAncestorsById', 'getStockTreeById'
    ]),
    ...mapGetters('stock_directions', [
        'getStockDirectionsTreeRoot', 'getStockDirectionsAncestorsById', 'getStockDirectionsById'
    ]),

    getPlaceholder() {
      if (this.value) {
        return this.getAncestors(this.value).map(e => e.name).reverse().join(' / ')
      } else {
        return null
      }
    },
    getLabel() {
      switch (this.type) {
        case 'category':
          return 'Ветвь ассортимента'

        case 'structure':
          return 'Папка номенклатуры'

        case 'direction':
          return 'Товарное направление'
      }
    },
    getTree() {
      switch(this.type) {
        case 'category':
          return this.getStockRootTree

        case 'structure':
          return this.getStockRootCategories

        case 'direction':
          return this.getStockDirectionsTreeRoot
      }
    },
    getAncestors() {
      switch(this.type) {
        case 'category':
          return this.getStockTreeAncestorsById

        case 'structure':
          return this.getStockCategoryAncestorsById

        case 'direction':
          return this.getStockDirectionsAncestorsById
      }
    },
    getById() {
      switch(this.type) {
        case 'category':
          return this.getStockTreeById

        case 'structure':
          return this.getStockCategoryById

        case 'direction':
          return this.getStockDirectionsById
      }
    }
  },

  methods: {
    ...mapActions('stock_directions', ['actionFetchStockDirections']),

    actionFetchData() {
      switch (this.type) {
        case 'direction':
          return this.actionFetchStockDirections()

          // todo Сместить логику иерархии загрузки данных в данный модуль
      }
    },

    selectCatalog(id) {
      this.$emit('input', id)
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>