<template>
  <v-hover #default="{ hover }">
    <v-card
        class="catalog-item"
        :elevation="hover ? 12 : 2"
        height="100%"
        @click="$router.push(link)"
    >
      <div class="d-flex">
        <v-catalog-img class="item-image" aspect-ratio="1.5" :src="photo"/>
        <div class="d-flex flex-column item-info">
          <v-tooltip color="black" left>
            <template #activator="{ on }">
              <v-icon class="mt-2" :color="iconColor1C" v-on="on">
                mdi-link-box-variant
              </v-icon>
            </template>
            {{ tooltipText1C }}
          </v-tooltip>
          <v-tooltip color="black" left>
            <template #activator="{ on }">
              <v-icon class="mt-3" :color="iconColorShop" v-on="on">
                mdi-shopping
              </v-icon>
            </template>
            {{ tooltipTextShop }}
          </v-tooltip>
          <ProductEditorDialog :id="item.id">
            <template #activator="{on, attrs}">
              <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click.stop
                  fab
                  x-small
                  color="primary"
                  class="mt-3"
                  width="22px"
                  height="22px"
                  style="margin-left: 9px"
              >
                <v-icon small color="white">mdi-pencil</v-icon>
              </v-btn>
            </template>
          </ProductEditorDialog>
        </div>
      </div>
      <v-card-text :title="item.name">
        {{ item.name }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import {routes} from '@/router'
import * as catalogTypes from '@/common/catalogTypes'
import {catalogItemMixin} from './mixins'
import ProductEditorDialog from "@/views/catalog/product-editor/ProductEditorDialog";

export default {
  components: {ProductEditorDialog},
  mixins: [catalogItemMixin],
  props: {
    item: Object,
    activeCatalogType: String
  },
  data: () => ({
    catalogTypes,
  }),
  computed: {
    link: function () {
      return {
        name: routes.catalogProduct,
        params: {catalogType: this.activeCatalogType, productId: this.item.id}
      }
    },
    photo: function () {
      return this.item && this.item.images && this.item.images.length
          ? this.item.images[0].url
          : ''
    }
  }
}
</script>

<style scoped lang="scss">
.catalog-item {
  .item-image {
    border-top-left-radius: inherit;
  }

  .item-info {
    max-width: 40px;
    width: 40px;
    flex: 0 0 40px;
  }
}
</style>
