<template>
  <v-row v-if="item" justify="start">
    <v-col cols="12">
      <Breadcrumbs :catalogId="catalogId" class="pa-0"/>
    </v-col>
    <v-form :disabled="!isAdmin">
      <v-col cols="12">
        <v-row>
          <v-col cols="3">
            <div class="product-image">
              <v-img
                  :src="photoBase64 || photo || noPhotoImg"
                  class="product-image__img"
              ></v-img>
              <div
                  v-if="isAdmin"
                  class="product-image__edit"
              >
                <template
                    v-if="!hasUnverifiedPhoto"
                >
                  Редактировать
                  <v-file-input
                      class="product-image__file"
                      v-model="file"
                      accept="image/png, image/jpeg"
                      hide-details
                  ></v-file-input>
                </template>
                <template v-else>
                  Изображение на модерации
                </template>
              </div>
            </div>
          </v-col>
          <v-col cols="9">
            <v-row class="align-center">
              <v-col cols="2">
                Наименование:
              </v-col>
              <v-col cols="10">
                <v-text-field
                    outlined
                    v-model="item.name"
                    hide-details
                    placeholder="Наименование"
                    dense
                    class="shrink"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col cols="2">
                Артикул:
              </v-col>
              <v-col cols="3">
                <v-text-field
                    outlined
                    v-model="item.article"
                    hide-details
                    placeholder="Артикул"
                    dense
                    class="shrink"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                Код 1С
              </v-col>
              <v-col cols="2">
                <v-text-field
                    outlined
                    v-model="item._1c_id"
                    hide-details
                    disabled
                    placeholder="Код 1С"
                    dense
                    class="shrink"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                UIN 1С
              </v-col>
              <v-col cols="3">
                <v-text-field
                    outlined
                    disabled
                    v-model="item.name"
                    hide-details
                    placeholder="UIN 1С"
                    dense
                    class="shrink"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col cols="2">
                Тип товара:
              </v-col>
              <v-col cols="5">
                <v-select
                    v-model="productTypeSelected"
                    item-text="name"
                    disabled
                    item-value="id"
                    hide-details
                    :items="[{name: 'Товар', id: 0}]"
                    outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col cols="3">
                <v-checkbox
                    label="Снят с производства"
                    value="red"
                    hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                    :input-value="!item.enabled" @click="item.enabled = !item.enabled"
                    label="Не выводить на сайт"
                    hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                    label="Строго кратно вложению"
                    v-model="item.only_contain_order"
                    hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" style="font-size: 14px">
        <v-tabs v-model="productCardTab">
          <v-tab :key="1">
            Единицы измерения и весогабаритные параметры
          </v-tab>
          <v-tab :key="2">
            Характеристики
          </v-tab>
          <v-tab :key="3">
            Группы номенклатуры и дерево ассортимента
          </v-tab>
          <v-tab :key="4">
            Цены и остатки
          </v-tab>
          <v-tab :key="5">
            SEO параметры и описание
          </v-tab>
          <v-tab :key="6">
            История изменения
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="productCardTab">
          <v-tab-item :key="1" class="pt-5">
            <v-row>
              <v-col cols="8">
                <v-row class="align-center">
                  <v-col cols="1">Базовая:</v-col>
                  <v-col cols="2">
                    <v-text-field
                        outlined
                        hide-details
                        placeholder="Базовая"
                        dense
                        disabled
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">Хранения остатков:</v-col>
                  <v-col cols="2">
                    <v-text-field
                        outlined
                        hide-details
                        placeholder="Хранения остатко"
                        dense
                        disabled
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">Заказов:</v-col>
                  <v-col cols="2">
                    <v-text-field
                        outlined
                        hide-details
                        placeholder="Заказов"
                        dense
                        disabled
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">Эдо:</v-col>
                  <v-col cols="2">
                    <v-text-field
                        outlined
                        hide-details
                        placeholder="Эдо"
                        dense
                        disabled
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="3">Прайс-лист и ценники:</v-col>
                  <v-col cols="3">
                    <v-text-field
                        outlined
                        hide-details
                        placeholder="Прайс-лист и ценники"
                        dense
                        disabled
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">Для расчета объема поставок:</v-col>
                  <v-col cols="3">
                    <v-text-field
                        outlined
                        hide-details
                        disabled
                        placeholder="Для расчета объема поставок"
                        dense
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-data-table
                      :headers="unitTableHeader"
                      hide-default-footer
                      :items="item.units_list"
                  >
                    <template #item.unit="{ item }">
                      {{ findProductUnit(item.unit)?.name }}
                    </template>
                  </v-data-table>
                </v-row>
              </v-col>
              <!--            attachment_truck
              attachment_box
              attachment_pallet
              minimum_batch-->
              <v-col cols="4">
                <h4>
                  Весогабаритные параметры
                </h4>
                <v-row class="align-center">
                  <v-col cols="4">Вложение в фуре</v-col>
                  <v-col cols="8">
                    <v-text-field
                        outlined
                        type="number"
                        min=".001"
                        step=".001"
                        v-model="item.attachment_truck"
                        hide-details
                        placeholder="Вложение в фуре"
                        dense
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="4">Вложение коробка:</v-col>
                  <v-col cols="8">
                    <v-text-field
                        outlined
                        v-model="item.attachment_box"
                        type="number"
                        min=".001"
                        step=".001"
                        hide-details
                        placeholder="Вложение коробка"
                        dense
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="4">Вложение на поддон:</v-col>
                  <v-col cols="8">
                    <v-text-field
                        outlined
                        v-model="item.attachment_pallet"
                        type="number"
                        min=".001"
                        step=".001"
                        hide-details
                        placeholder="Вложение на поддоне"
                        dense
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="4">Минимальная партия:</v-col>
                  <v-col cols="8">
                    <v-text-field
                        outlined
                        v-model="item.minimum_batch"
                        type="number"
                        min=".001"
                        step=".001"
                        hide-details
                        placeholder="Минимальная партия"
                        dense
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row><br></v-row>

                <v-row class="align-center">
                  <v-col cols="3">Ширина:</v-col>
                  <v-col cols="9">
                    <v-text-field
                        outlined
                        hide-details
                        v-model="item.piece_width"
                        placeholder="Ширина"
                        type="number"
                        min=".001"
                        step=".001"
                        dense
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="3">Высота:</v-col>
                  <v-col cols="9">
                    <v-text-field
                        outlined
                        hide-details
                        type="number"
                        min=".001"
                        v-model="item.piece_height"
                        step=".001"
                        placeholder="Высота"
                        dense
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="3">Глубина:</v-col>
                  <v-col cols="9">
                    <v-text-field
                        outlined
                        hide-details
                        placeholder="Глубина"
                        type="number"
                        v-model="item.piece_depth"
                        min=".001"
                        step=".001"
                        dense
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="3">Вес:</v-col>
                  <v-col cols="9">
                    <v-text-field
                        outlined
                        hide-details
                        v-model="item.piece_weight"
                        placeholder="Вес"
                        type="number"
                        min=".001"
                        step=".001"
                        dense
                        class="shrink"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-row class="pt-4">
              <v-col cols="6">
                <h4>Параметры товара</h4>
                <v-data-table
                    :headers="featureTableHeader"
                    hide-default-footer
                    :items="productFeature"
                >
                  <template #item.name="{ item: feature  }">
                    {{ getStockFeatureById(feature.feature)?.name }}
                  </template>
                  <template #item.specific="{ item: feature  }">
                    <div v-if="feature.isProduct">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                      <span
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        Инд.
                      </span>
                        </template>
                        <span>
                      Параметр уникален для товара
                    </span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="6">
                   <h4>Параметры общие</h4>
                <v-data-table
                    :headers="featureTableHeader"
                    hide-default-footer
                    :items="categoryTreeFeatures"
                >
                  <template #item.name="{ item: feature  }">
                    {{ getStockFeatureById(feature.feature)?.name }}
                  </template>
                  <template #item.specific="{ item: feature  }">
                    <div v-if="feature.isProduct">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                      <span
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        Инд.
                      </span>
                        </template>
                        <span>
                      Параметр уникален для товара
                    </span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :key="3">
            <CatalogSelector v-model="item.category" :type="CatalogSelector.TYPE_CATEGORY"></CatalogSelector>
            <CatalogSelector v-model="item.structure" :type="CatalogSelector.TYPE_STRUCTURE"></CatalogSelector>
          </v-tab-item>
          <v-tab-item :key="4">В процессе создания</v-tab-item>
          <v-tab-item :key="5">В процессе создания</v-tab-item>
          <v-tab-item :key="6">
            <v-data-table
                :headers="imageUploadedTableHeader"
                hide-default-footer
                :items="imageUploaded"
            >
              <template #item.uploaded_at="{ item: image  }">
                {{ new Date(image.uploaded_at).toLocaleString() }}
              </template>
              <template #item.moderated_at="{ item: image  }">
                {{ new Date(image.moderated_at).toLocaleString() }}
              </template>
              <template #item.status="{ item: image  }">
                <template v-if="image.status === 'accepted'">
                  Принята
                </template>
                <template v-else>
                  Отклонена
                </template>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" class="justify-end d-flex">
        <v-btn
            depressed
            color="normal mr-4"
            @click="$router.go(-1)"
        >
          Назад
        </v-btn>
        <v-btn
            depressed
            :disabled="saving || !isAdmin"
            @click="storeProduct"
            color="success"
        >
          Сохранить изменения
        </v-btn>
      </v-col>
    </v-form>
  </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import permissions from '@/common/permissions'
import * as catalogTypes from '@/common/catalogTypes'
import {scrollToTarget} from '@/common/scroll'
import {routes} from '@/router'
import imagesApi from '@/services/imagesApi'
import Breadcrumbs from './Breadcrumbs'
import EditableTitle from './EditableTitle'
import DialogTreeSelect from './DialogTreeSelect'
import DialogConfirm from './DialogConfirm'
import ProductEditorDialog from '@/views/catalog/product-editor/ProductEditorDialog'
import store from "@/store";
import stockProductsApi from "@/services/stockProductsApi";
import noPhotoImg from '@/assets/images/nophoto.png'
import CatalogSelector from "@/views/catalog/product-editor/CatalogSelector.vue";
import getStockProductModerationById from "@/services/api";

const identifierTypes = {
  id: 'id',
  _1c_id: '_1c_id'
}

export default {
  components: {
    CatalogSelector,
    Breadcrumbs,
    EditableTitle,
    DialogTreeSelect,
    DialogConfirm,
    ProductEditorDialog,
  },
  props: {
    productId: Number
  },
  data: () => ({
    noPhotoImg,
    file: null,
    photoBase64: null,
    item: {},
    saving: false,
    hasUnverifiedPhoto: false,
    valuesDialogTreeSelect: {
      [catalogTypes.structure]: null,
      [catalogTypes.category]: null,
      [catalogTypes.direction]: null
    },
    // диалоги выбора
    showDialogTreeSelect: {
      [catalogTypes.structure]: false,
      [catalogTypes.category]: false,
      [catalogTypes.direction]: false
    },
    // диалоги подтверждения
    showDialogConfirm: {
      [catalogTypes.structure]: false,
      [catalogTypes.category]: false,
      [catalogTypes.direction]: false
    },
    // дубликат
    duplicate: {
      identifierType: identifierTypes._1c_id,
      identifierValue: '',
      dialogDisplay: false,
      dialogConfirmDisplay: false,
      requestInProgress: false
    },


    // Temp hardcode for design
    productTypeSelected: 0,
    productCardTab: null,
    unitTableHeader: [
      {text: 'Код 1С', value: '_1c_id', align: 'center', sortable: false},
      {text: 'По классификатору', align: 'center', sortable: false},
      {text: 'Наименование', value: 'unit', align: 'center', sortable: false},
      {text: 'К.', value: 'coefficient', align: 'center', sortable: false},
      {text: 'Вес (кг.)', value: 'weight', align: 'center', sortable: false},
      {text: 'Объем ( м. кв.)', value: 'volume', align: 'center', sortable: false},
      {text: 'Площадь', value: 'square', align: 'center', sortable: false},
    ],
    featureTableHeader: [
      {text: 'Название', value: 'name'},
      {text: 'Значение', value: 'value'},
      {text: 'особенности', value: 'specific'},
    ],
    imageUploadedTableHeader: [
      {text: 'Дата заливки', value: 'uploaded_at'},
      {text: 'Кто залил', value: 'uploaded_by'},
      {text: 'Дата модерации', value: 'moderated_at'},
      {text: 'Модератор', value: 'moderated_by'},
      {text: 'Статус', value: 'status'},
    ],
    imageUploaded: []

  }),
  created() {
    this.catalogTypes = catalogTypes
    this.identifierTypes = identifierTypes
    this.routes = routes
    this.actionFetchStockProductById(this.productId).then(data => {
      this.item = {...data};
      this.item.images = this.item.images.map(i => {
        if (i.url.startsWith('/'))
          i.url = process.env.VUE_APP_MEDIA_HOST + i.url
        return i
      })
    })
    if (this.$store.state.stock_features.stock_features.length === 0) {
      this.actionFetchStockFeatures();
    }
    this.actionFetchStockDirections()
    if (this.hasImagesPermission) {
      this.fetchImagesUnverified()
    }
    this.actionFetchStockUnits()
    this.actionFetchProductModeration(this.productId).then(data => {
      this.imageUploaded = data;
    });
  },
  mounted() {
    scrollToTarget('#top-scroll-anchor')
  },
  watch: {
    productId: function () {
      this.actionFetchStockProductById(this.productId)
      if (this.hasImagesPermission) {
        this.fetchImagesUnverified()
      }
    },
    file() {
      if (this.file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.photoBase64 = reader.result
        };
        reader.readAsDataURL(this.file);
      }
    }
  },
  computed: {
    CatalogSelector() {
      return CatalogSelector
    },
    ...mapGetters('auth', ['getCurrentUserHasPermission']),
    ...mapGetters('app_catalog', ['activeCatalogType']),
    ...mapGetters('stock_products', [
      'getStockProductById',
      'getStockProductsFetching',
      'getStockProductsUpdating'
    ]),
    ...mapGetters('stock_categories', [
      'getStockCategoryById',
      'getStockCategoryAncestorsById'
    ]),
    ...mapGetters('stock_tree', [
      'getStockTreeById',
      'getStockRootTree',
      'getStockTreeAncestorsById'
    ]),
    ...mapGetters('stock_directions', [
      'getStockDirectionsById',
      'getStockDirectionsTreeRoot',
      'getStockDirectionsAncestorsById'
    ]),
    ...mapGetters('stock_categories', ['getStockRootCategories']),
    ...mapGetters('images', ['getUploading']),
    ...mapGetters('stock_tree', ['getStockTreeAncestorsById',]),
    ...mapGetters('stock_features', ['getStockFeatureById']),
    photo: function () {
      return this.item && this.item.images && this.item.images.length
          ? this.item.images[0].url
          : ''
    },
    catalogId: function () {
      if (this.item) {
        return catalogTypes.structure === this.activeCatalogType
            ? this.item.structure
            : this.item.category
      }
      return null
    },
    editable: function () {
      return !this.item.created_from
    },
    hasImagesPermission: function () {
      return this.getCurrentUserHasPermission(permissions.appImages)
    },
    isAdmin: function () {
      return this.getCurrentUserHasPermission(permissions.appCatalogAdmin)
    },
    structure: function () {
      return this.item ? this.getStockCategoryById(this.item.structure) : null
    },
    structureName: function () {
      return (this.structure && this.structure.name) || '---'
    },
    category: function () {
      return this.item ? this.getStockTreeById(this.item.category) : null
    },
    categoryName: function () {
      return (this.category && this.category.name) || '---'
    },
    direction: function () {
      return this.item ? this.getStockDirectionsById(this.item.direction) : null
    },
    directionName: function () {
      return (this.direction && this.direction.name) || '---'
    },
    dialogTreeSelectOpenStructure: function () {
      return this.getStockCategoryAncestorsById(this.item.structure).map(
          item => item.id
      )
    },
    dialogTreeSelectOpenCategory: function () {
      return this.getStockTreeAncestorsById(this.item.category).map(
          item => item.id
      )
    },
    dialogTreeSelectOpenDirection: function () {
      return this.getStockDirectionsAncestorsById(this.item.direction).map(
          item => item.id
      )
    },
    categoryTreeFeatures: function () {
      const categories = this.getStockTreeAncestorsById(this.item.category);
      return categories.map(category => category.feature_values).flat(Infinity)
    },
    productFeature: function () {
      return this.item.feature_values.map(feature => {
        return {...feature, isProduct: true}
      });
    }
  },
  methods: {
    ...mapActions('stock_units', ['actionFetchStockUnits']),
    ...mapActions('stock_tree', ['actionFetchStockTreeById']),
    ...mapActions('stock_categories', ['actionFetchStockCategoryById']),
    ...mapActions('stock_products', [
      'actionFetchStockProductById',
      'actionFetchStockProductById1C',
      'actionPatchStockProduct',
      'actionDuplicateStockProduct',
      'actionFetchProductModeration',
    ]),
    ...mapActions('stock_directions', ['actionFetchStockDirections']),
    ...mapActions('images', ['actionUploadImage']),
    ...mapActions('stock_features', ['actionFetchStockFeatures']),
    updateProduct: function (data) {
      return this.actionPatchStockProduct({
        id: this.item.id,
        data
      })
    },
    uploadPhoto: function () {
      console.log('upload')
      this.actionUploadImage({
        type: catalogTypes.product,
        id: this.item.id,
        file: this.file
      }).then(() => {
        this.file = null
        this.fetchImagesUnverified()
      })
    },
    getProductModeration: function () {
      console.log(this.actionFetchProductModeration)
    },
    fetchImagesUnverified: function () {
      this.hasUnverifiedPhoto = false
      imagesApi
          .getModerate(catalogTypes.product, {
            filterByTarget: this.productId,
            filterUnverified: true
          })
          .then(data => {
            this.hasUnverifiedPhoto = data.results.filter(r => r.target.id === this.item.id).length > 0
          })
    },
    dialogConfirmDisplay: function (type, value) {
      this.showDialogConfirm = {
        ...this.showDialogConfirm,
        [type]: value
      }
    },
    dialogConfirmSuccess: function (type) {
      this.updateProduct({[type]: this.valuesDialogTreeSelect[type]}).then(
          () => {
            this.showDialogConfirm[type] = false
          }
      )
    },
    dialogTreeSelectSuccess: function (type, value) {
      this.valuesDialogTreeSelect[type] = value
      this.showDialogTreeSelect[type] = false
      this.showDialogConfirm[type] = true
    },
    dialogTreeSelectDisplay: function (type, value) {
      this.showDialogTreeSelect = {
        ...this.showDialogTreeSelect,
        [type]: value
      }
    },
    duplicateDialogOpen: function () {
      this.duplicate = {
        ...this.duplicate,
        identifierType: identifierTypes._1c_id,
        identifierValue: '',
        dialogDisplay: true,
        requestInProgress: false
      }
    },
    duplicateDialogSuccess: function () {
      if (!this.duplicate.identifierValue.trim().length) return
      this.duplicate.dialogDisplay = false
      this.duplicate.dialogConfirmDisplay = true
    },
    duplicateDialogConfirmSuccess: function () {
      this.duplicate.requestInProgress = true
      const fetchProductMethod =
          this.duplicate.identifierType === identifierTypes.id
              ? this.actionFetchStockProductById
              : this.actionFetchStockProductById1C
      fetchProductMethod(this.duplicate.identifierValue.trim()).then(
          targetItemData => {
            this.actionDuplicateStockProduct({
              id: this.item.id,
              target_id: targetItemData.id
            })
                .then(() => {
                  const catalogId =
                      this.activeCatalogType === catalogTypes.structure
                          ? this.item.structure
                          : this.item.category
                  const catalogFetchMethod =
                      this.activeCatalogType === catalogTypes.structure
                          ? this.actionFetchStockCategoryById
                          : this.actionFetchStockTreeById
                  catalogFetchMethod(catalogId).then(() => {
                    this.duplicate.dialogConfirmDisplay = false
                    this.duplicate.requestInProgress = false
                    this.$router.push({
                      name: routes.catalogDetail,
                      params: {
                        catalogType: this.activeCatalogType,
                        catalogId
                      }
                    })
                  })
                })
                .catch(() => {
                  this.duplicate.dialogConfirmDisplay = false
                  this.duplicate.requestInProgress = false
                })
          }
      )
    },
    findProductUnit(productUnitId) {
      if (this.$store.state.stock_units.stock_units.length) {
        return this.$store.state.stock_units.stock_units.find(stock_unit => stock_unit.id === productUnitId)
      }
      return null
    },
    /**
     * Шаг создания/сохранения 1: Сохранение данных
     * @return {Promise<any>}
     */
    storeProduct() {
      this.saving = true
      let product_data = this.item
      delete product_data['feature_values']

      if (product_data.id) {
        this.actionPatchStockProduct({
          id: product_data.id,
          data: product_data
        })
            .then(() => this.file && this.uploadPhoto())
            .finally(() => (this.saving = false))
      }
    },
  },

}
</script>

<style scoped lang="scss">
.item-container {
  position: relative;
}

.item-info {
  > .col {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

.export-form {
  position: relative;
}

.product-image {
  position: relative;
  width: 100%;
  height: 100%;

  &__img {
    width: 100%;
    height: 100%;
    background-color: #9e9e9e;
  }

  &__edit, &__file {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px 10px 0 0;
    background-color: #C9F088;
    transition: background-color .2s ease;

    &:hover {
      background-color: #a3c56c;
    }
  }

  &__file {
    cursor: pointer;
    opacity: 0;
    padding: 0;
    margin: 0;
  }
}
</style>
