<template>
  <v-dialog :value="value" :width="width" persistent scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          <slot name="title"></slot>
        </span>
      </v-card-title>
      <v-card-text>
        <slot name="text"></slot>
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <slot name="actions">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('close')" color="grey darken-1" outlined>
            Отмена
          </v-btn>
          <v-btn @click="$emit('confirm')" color="success" outlined>
            Подтвердить
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '500px'
    }
  }
}
</script>
