<template>
  <v-dialog :persistent="processing" v-model="show" max-width="600">
    <template #activator="{ on, attrs }">
      <v-btn small :disabled="disabled" color="primary" v-on="on" v-bind="attrs">Импортировать</v-btn>
    </template>
    <v-card>
      <v-card-title>Импортировать товар</v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-text-field
              :rules="rules"
              outlined
              label="Ссылка на товар"
              :disabled="processing"
              v-model.trim="link"/>
        </v-form>
        Поддерживаемые ресурсы:
        <ul v-if="supported_domains.length">
          <li v-for="k in supported_domains" :key="k">
            <a :href="k" target="_blank">{{ k }}</a>
          </li>
        </ul>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn @click="show = false" :disabled="processing" outlined color="secondary">Отмена</v-btn>
        <v-btn
            @click="process"
            :disabled="processing || !valid || !link"
            outlined
            color="primary"
        >Импортировать
        </v-btn>
      </v-card-actions>
      <v-progress-linear indeterminate :active="processing"/>
    </v-card>
  </v-dialog>
</template>

<script>
import importsApi from "@/services/importsApi";

const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }

  return true;
};


export default {
  name: "ProductImportDialog",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    show: false,
    link: null,
    supported_domains: [],
    processing: false,
    valid: false,
    rules: [
      v => !!v || 'Укажите ссылку',
      v => isValidUrl(v) || 'Некорректная ссылка',
    ]
  }),

  created() {
    this.processing = true

    importsApi
        .listSources()
        .then(supported_domains => {
          this.supported_domains = supported_domains,
              this.processing = false

          this.rules = [
            this.rules[0],
            this.rules[1],
            v => !!this.supported_domains.filter(d =>
                this.link && v.startsWith(d)
            ).length || 'Ресурс не поддерживается'
          ]
        })
  },

  methods: {
    process() {
      this.processing = true

      importsApi
          .parseProductLink(this.link)
          .then(card => {
            this.$emit('product-card', card)
            this.show = false
          })
    }
  },

  watch: {
    show(to) {
      if (to) {
        this.link = null
        this.processing = false
      }
    }
  }
}
</script>

<style scoped>

</style>