<template>
  <v-dialog :value="value" :width="width" persistent scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          <slot name="title"></slot>
        </span>
      </v-card-title>
      <v-card-text>
        <v-treeview
          v-if="value"
          :items="items"
          :active="active"
          :open="open"
          class="py-3"
          open-on-click
          dense
        >
          <template #label="{ item }">
            <slot name="label" :item="item"></slot>
          </template>
          <template #append="{ item }">
            <v-btn
              v-if="itemSelectable(item)"
              @click.stop="select(item)"
              :color="buttonColor(item)"
              class="mr-2"
              small
              depressed
            >
              {{ buttonText(item) }}
            </v-btn>
          </template>
        </v-treeview>
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="close" color="grey darken-1" outlined>Отмена</v-btn>
        <v-btn
          @click="$emit('confirm', newSelectedItem)"
          :disabled="confirmDisabled"
          color="success"
          outlined
        >
          Продолжить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    itemSelectable: {
      type: Function,
      default: () => true
    },
    active: {
      type: Array,
      default: () => []
    },
    open: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: '600px'
    }
  },
  data: () => ({
    newSelectedItem: null
  }),
  computed: {
    confirmDisabled: function() {
      return !this.newSelectedItem
    }
  },
  methods: {
    close: function() {
      this.newSelectedItem = null
      this.$emit('close')
    },
    select: function(selected) {
      this.newSelectedItem = selected.id
    },
    buttonColor: function(item) {
      return this.newSelectedItem === item.id ? 'success' : 'primary'
    },
    buttonText: function(item) {
      return this.newSelectedItem === item.id ? 'Выбрано' : 'Выбрать'
    }
  }
}
</script>
