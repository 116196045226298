import {mapGetters} from 'vuex'
import * as catalogTypes from '@/common/catalogTypes'

export default {
    computed: {
        ...mapGetters('app_catalog', ['activeCatalogType']),
        ...mapGetters('stock_categories', [
            'getStockRootCategories',
            'getStockCategoriesFetching',
            'getFilteredStockRootCategories',
        ]),
        ...mapGetters('stock_tree', [
            'getStockRootTree',
            'getStockTreeFetching',
            'getFilteredStockRootTrees'
        ]),

        // Элементы каталога
        catalogItems: function () {
            switch (this.activeCatalogType) {
                case catalogTypes.structure:
                    return this.getStockRootCategories
                case catalogTypes.category:
                    return this.getStockRootTree
            }

            return []
        },
        // Загрузка данных
        catalogIsLoading: function () {
            return this.getStockCategoriesFetching || this.getStockTreeFetching
        }
    }
}
