import api from './api'

export default {
    /**
     * Возвращает список доступных сайтов для импорта карточек товаров
     * @return Promise<T>
     */
    listSources() {
        return api.get('/imports/list-sources/').then(({ data }) => data)
    },

    /**
     * Пытается спарсить по переданной ссылки карточку товара и возвращает её содержимое
     * @param link Ссылка на карточку товара
     * @return Promise<T>
     */
    parseProductLink(link) {
        return api.post('/imports/parse-product-link/', {link}).then(({data}) => data)
    }
}
