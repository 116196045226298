<template>
  <v-dialog scrollable v-model="show" max-width="1150" persistent>
    <template #activator="{on, attrs}">
      <span v-show="
        $store.state.auth.current_user
        && $store.state.auth.current_user.permissions.indexOf('app.catalog.admin') !== -1
      ">
        <slot name="activator" v-bind="{on, attrs}">
          <v-btn v-on="on" v-bind="attrs">
            <span v-if="id">{{ editButtonText }}</span>
            <span v-else>Создать товар</span>
          </v-btn>
        </slot>
      </span>
    </template>
    <v-card :loading="saving || fetching">
      <v-progress-linear
          :active="saving || fetching"
          :indeterminate="saving || fetching"
      ></v-progress-linear>
      <v-card-title>
        <div v-if="id" class="d-flex justify-space-between" style="width: 100%">
          <span>Редактирование товара.</span>
          <span v-if="a_1c_id" style="color: darkgoldenrod">
            1C: {{ a_1c_id }}
            <v-btn @click="copy_1c_id" class="mt-n2" fab text color="grey" width="40" height="40">
              <v-icon>mdi-clipboard-multiple-outline</v-icon>
            </v-btn>
          </span>
          <span style="color: darkgrey">#{{ id }}</span>
        </div>
        <div v-else class="d-flex justify-space-between" style="width: 100%">
          <div>Создание товара</div>
          <ProductImportDialog :disabled="saving || fetching" @product-card="receiveCard"/>
        </div>
      </v-card-title>
      <v-card-text ref="body">
        <v-form ref="form" :disabled="saving || fetching">
          <!-- region IMAGES -->
          <v-carousel
              height="400"
              v-model="slide"
              hide-delimiters
          >
            <v-carousel-item v-for="(image, i) in images" :key="i">
              <v-sheet class="fill-height d-flex flex-column-reverse">
                <v-row justify="center" class="absolute mt-6" style="z-index: 1000">
                  <v-btn
                      @click="images = [...images.slice(0, i), ...images.slice(i + 1)]"
                      title="Удалить изображение"
                      fab
                      :title="images.url"
                      small
                      class="mt-n16 mx-2 absolute"
                      :disabled="saving || fetching">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                  <v-tooltip top>
                    <template #activator="{on, attrs}">
                      <v-btn
                          v-if="i !== 0"
                          title="Сделать главным"
                          fab
                          small
                          class="mt-n16 absolute mx-2"
                          @click="images = [image, ...images.filter(i => i !== image)]; slide = 0"
                          v-on="on"
                          v-bind="attrs"
                          :disabled="saving || fetching"
                      >
                        <v-icon color="green">mdi-check-bold</v-icon>
                      </v-btn>
                    </template>
                    <span>Делает изображение главным у товара</span>
                  </v-tooltip>
                </v-row>
                <v-row style="height: 400px;">
                  <v-img
                      contain
                      class="fill-height"
                      :src="image.url"
                  />
                </v-row>
              </v-sheet>
            </v-carousel-item>

            <v-carousel-item :key="-1" v-if="images.length === 0">
              <v-sheet class="fill-height">
                <v-img contain class="fill-height" :src="noPhotoImg" eager/>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
          <div class="mt-4 carousel-images flex-wrap">
            <div
                :class="{'carousel-image-container': true, 'selected': slide === i}"
                v-for="(image, i) in images"
                :key="i">
              <v-img
                  class="d-inline-flex mx-1"
                  @click="slide = i"
                  width="67"
                  height="67"
                  max-width="67"
                  max-height="67"
                  contain
                  :src="image.url"
                  @error="image.url = brokenPhotoImg"
              />
            </div>
            <div class="add-image-container">
              <v-tooltip top>
                <template #activator="{on, attrs}">
                  <v-btn
                      fab
                      :disabled="saving || fetching"
                      color="primary"
                      large
                      class="mx-1"
                      v-on="on"
                      v-bind="attrs"
                      @click="$refs['image_input'].click()"
                  >
                    <v-icon large>mdi-image-plus</v-icon>
                  </v-btn>
                </template>
                <span>Выбрать изображение с компьютера</span>
              </v-tooltip>
            </div>
          </div>
          <input type="file" accept="image/jpeg, image/png" class="d-none" @change="fileChanged" ref="image_input"/>
          <!-- endregion -->
          <h3 class="my-4" v-if="created_from">
            Создано из
            <a :href="created_from" target="_blank">{{ created_from }}</a>
          </h3>
          <v-text-field
              label="Наименование"
              v-model.trim="name"
              :rules="rules.name"
              :disabled="!!created_from"/>
          <div class="d-flex justify-between">
            <v-text-field
                label="Цена (закупочная)"
                type="number"
                min=".00"
                step=".01"
                v-model="price1"
                style="width: 100px !important"
                :rules="rules.price"
                :disabled="!!created_from"/>
            <v-autocomplete
                :items="$store.state.stock_units.stock_units"
                item-text="name"
                item-value="id"
                :rules="rules.unit"
                v-model="unit"
                label="Ед. изм."
                style="width: 100px !important"
                class="px-1 col-1"
                :disabled="!!created_from"/>

            <UnitCoefficientsDialog v-model="units_list"/>

            <v-text-field
                v-model.trim="article"
                placeholder="Артикул"
                :messages.html="['Через | можно указать несколько, например \'6300A-010|8570-SP5\'']"/>
          </div>

          <BrandSelector
              v-model="brand"
              @change="brand = $event"
          ></BrandSelector>
          <CatalogSelector v-model="category" :type="CatalogSelector.TYPE_CATEGORY"></CatalogSelector>
          <CatalogSelector v-model="structure" :type="CatalogSelector.TYPE_STRUCTURE"></CatalogSelector>
          <CatalogSelector v-model="direction" :clearable="true"
                           :type="CatalogSelector.TYPE_DIRECTION"></CatalogSelector>

          <v-textarea v-model="description" label="Описание" row-height="6" auto-grow></v-textarea>
          <h2 class="text-center mt-8">Характеристики</h2>

          <v-data-table
              :items="features"
              :headers="features_headers"
              hide-default-footer
              disable-pagination
              dense
          >
            <template #item.feature="{item}">
              <v-autocomplete
                  v-model.trim="item.feature"
                  v-if="$store.state.stock_features.stock_features"
                  :items="$store.state.stock_features.stock_features"
                  :search-input.sync="item.feature__search"
                  :rules="rules.feature"
                  return-object
                  item-value="id"
                  item-text="name"
                  @keydown.enter.prevent="createFeature(item)"
              >
                <template #no-data>
                  <v-list-item @click="createFeature(item)" v-if="item.feature__search">
                    <v-list-item-content>
                      <v-list-item-title>
                        Добавить свойство "<u><i>{{ item.feature__search }}</i></u>"
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </template>
            <template #item.value="{item}">
              <v-text-field :rules="rules.feature_value" v-model.trim="item.value"></v-text-field>
            </template>
            <template #item.btn="{item}">
              <v-icon @click="features = features.filter(f => f !== item)" class="mx-n2">mdi-window-close</v-icon>
            </template>
            <template #footer>
              <v-btn
                  fab
                  x-small
                  @click="pushFeature"
                  color="primary"
                  class="mx-auto d-block"
                  :disabled="saving || fetching"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn outlined :disabled="fetching || saving" @click="show = false" color="secondary">Отмена</v-btn>
        <v-btn outlined :disabled="fetching || saving" @click="storeProduct" color="primary">
          <span v-if="id">Сохранить</span>
          <span v-else>Создать</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import noPhotoImg from '@/assets/images/nophoto.png'
import brokenPhotoImg from '@/assets/images/brokenphoto.png'
import {mapActions, mapGetters, mapMutations} from "vuex";
import BrandSelector from "@/views/catalog/product-editor/BrandSelector";
import CatalogSelector from "@/views/catalog/product-editor/CatalogSelector";
import store from '@/store'
import ProductImportDialog from "@/views/catalog/product-editor/ProductImportDialog";
import UnitCoefficientsDialog from '@/views/catalog/product-editor/UnitCoefficientsDialog';
import * as permissions from '@/common/permissions'
import stockProductsApi from '@/services/stockProductsApi'

export default {
  name: "ProductEditorDialog",
  components: {ProductImportDialog, CatalogSelector, BrandSelector, UnitCoefficientsDialog},

  props: {
    id: Number,
    defaultCategory: [Number, String],
    defaultStructure: [Number, String],
    editButtonText: {
      type: String,
      default: 'Редактировать товар',
    }
  },

  data: () => ({
    CatalogSelector,
    noPhotoImg,
    brokenPhotoImg,
    show: false,
    permissions,
    slide: 0,
    features_headers: [
      {
        text: 'Свойство',
        value: 'feature',
      },
      {
        text: 'Значение',
        value: 'value',
      },
      {
        text: '',
        value: 'btn',
        width: '1%',
        padding: '0'
      }
    ],
    rules: {
      name: [
        v => (v || '').length && v.length < 1024 || 'Длина имени не может быть более 1024 символов',
        v => !!v || 'Имя не указано'
      ],
      price: [
        v => (v !== null && v.length > 0) || 'Цена не указанна',
      ],
      unit: [
        v => !!v || 'Ед. изм. не указанна'
      ],
      feature: [
        v => !!v || 'Имя свойства не указано' // 😡
      ],
      feature_value: [
        v => !!v || 'Значение свойства не указано',
        v => (v || '').length < 512 || 'Максимальная длина 512 символов'
      ]
    },
    saving: false,

    /**
     * Массив изображений.
     * Либо ссылка на существующее изображение в агрегаторе, либо закодированная в base64 строка
     * @type {{id: {null, string}, url: string}[]}
     */
    images: [],

    // Ветвь ассортимента
    category: null,

    // Папка номенклатуры
    structure: null,

    a_1c_id: null,
    name: null,
    article: null,
    price1: 0.00,
    unit: null,
    brand: null,
    direction: null,
    enabled: true,
    distributor: null,
    description: null,
    units_list: [],

    /** @type {{feature: {id: string, name: string, enabled: boolean}, feature__search: string, value: string}[]} */
    features: [],
    created_from: null,
  }),

  computed: {
    ...mapGetters('stock_units', ['getStockUnitsFetching']),
    ...mapGetters('stock_brands', ['getStockBrandsFetching', 'getStockBrandById', 'getStockBrandCategoryById']),
    ...mapGetters('stock_features', [
      'getStockFeaturesAdding', 'getStockFeaturesFetching', 'getStockFeatures', 'getStockFeatureById'
    ]),
    ...mapGetters('stock_products', [
      'getStockProductById',
    ]),

    fetching() {
      return (
          this.getStockUnitsFetching
          || this.getStockBrandsFetching
          || this.getStockFeaturesFetching
          || this.getStockFeaturesAdding
      )
    }
  },

  methods: {
    ...mapActions('stock_units', ['actionFetchStockUnits']),
    ...mapActions('stock_brands', ['actionFetchStockBrands']),
    ...mapActions('stock_products', [
      'actionFetchStockProductById',
      'actionPatchStockProduct',
      'actionCreateStockProduct',
      'actionDeleteStockProductImage',
      'actionAddStockProductImage',
      'actionUpdateStockProductCoefficients',
    ]),
    ...mapActions('stock_features', [
      'actionFetchStockFeatures', 'actionCreateStockFeature', 'actionCreateStockFeatureValue'
    ]),

    fetchProduct() {
      let fetch_actions = []

      if (this.$store.state.stock_features.stock_features.length === 0)
        this.actionFetchStockFeatures()

      if (this.$store.state.stock_units.stock_units.length === 0)
        fetch_actions.push(this.actionFetchStockUnits())

      if (this.$store.state.stock_brands.stock_brands.length === 0)
        fetch_actions.push(this.actionFetchStockBrands())

      Promise
          .allSettled(fetch_actions)
          .finally(() => {

            if (this.id) {
              this
                  .actionFetchStockProductById(this.id)
                  .then(data => {
                    data = JSON.parse(JSON.stringify(data))

                    this.a_1c_id = data._1c_id
                    this.name = data.name
                    this.brand = data.brand
                    this.article = data.article
                    this.unit = data.unit
                    this.price1 = data.price1
                    this.category = data.category
                    this.structure = data.structure
                    this.direction = data.direction
                    this.description = data.description
                    this.created_from = data.created_from
                    this.units_list = data.units_list
                    this.images = data.images.map(i => {
                      if (i.url.startsWith('/'))
                        i.url = process.env.VUE_APP_MEDIA_HOST + i.url

                      return i
                    })

                    new Promise((resolve) => {
                      if (data.feature_values.filter(fv => this.getStockFeatureById(fv.feature) == null).length > 0) {
                        this.actionFetchStockFeatures().then(() => resolve())
                      } else {
                        resolve()
                      }
                    }).then(() => {
                      this.features = data.feature_values.map(f_value => ({
                        feature: JSON.parse(JSON.stringify(this.getStockFeatureById(f_value.feature))),
                        feature__search: null,
                        value: f_value.value,
                      }))
                    })
                  })
            } else {
              this.a_1c_id = null
              this.name = null
              this.brand = null
              this.article = null
              this.unit = null
              this.price1 = 0.00
              this.category = null
              this.structure = null
              this.direction = null
              this.description = null
              this.images = []
              this.features = []
              this.units_list = []

              if (this.defaultCategory)
                this.category = parseInt(this.defaultCategory)

              if (this.defaultStructure)
                this.structure = parseInt(this.defaultStructure)
            }
          })
    },

    copy_1c_id() {
      navigator.clipboard.writeText(this.a_1c_id)
    },

    fileChanged(e) {
      if (!e.target.files.length)
        return

      /** @type File */ let file = e.target.files[0]

      if (['png', 'jpg', 'jpeg', 'bmp', 'webm'].indexOf(e.target.value.split('.').pop().toLowerCase()) === -1) {
        e.target.value = null
        store.dispatch('actionAddErrorMessage', 'Некоректный формат изображения', {root: true})
        return
      }

      let reader = new FileReader()
      reader.onloadend = () => {
        this.images.push({
          id: null,
          url: reader.result
        })
        this.slide = this.images.length - 1
      }
      reader.readAsDataURL(file)

    },

    createFeature(item) {
      this
          .actionCreateStockFeature({name: item.feature__search})
          .then(feature => {
            this.$nextTick(() => item.feature = JSON.parse(JSON.stringify(feature)))
          })
    },

    pushFeature() {
      this.features.push({
        feature: null,
        feature__search: null,
        value: null
      })

      this.$nextTick(() => {
        this.$refs.body.scrollTop = this.$refs.body.scrollHeight
      })
    },

    /**
     * Отправляет свойства товара на создание/обновление, возвразщает Promise
     * в котором передаётся массив выбранных значений свойств.
     * @return {Promise<T>}
     */
    fetchFeatureValuesIds() {
      let f_value_get_or_create_requests = []

      this.features.map(f => {
        f_value_get_or_create_requests.push(
            this.actionCreateStockFeatureValue({feature: f.feature.id, value: f.value})
        )
      })

      return Promise
          .allSettled(f_value_get_or_create_requests)
          .then(values => values.map(({value}) => value))
    },

    /**
     * Шаг создания/сохранения 1: Сохранение данных
     * @return {Promise<any>}
     */
    storeProduct() {
      this.features = this.features.filter(f => {
        return !!f.feature && !!f.value
      })

      if (!this.$refs.form.validate()) {
        return store.dispatch('actionAddErrorMessage', 'Форма заполнена некорректно', {root: true})
      }

      this.saving = true

      this
          .fetchFeatureValuesIds()
          .then(/** @type {{id: integer, name: string, value: any, feature: number}[]} */values => {
            let product_data = {
              name: this.name,
              category: this.category,
              structure: this.structure,
              article: this.article,
              price1: this.price1,
              unit: this.unit,
              brand: this.brand,
              direction: this.direction,
              enabled: this.enabled,
              description: this.description,
              feature_values: values.map(fvalue => fvalue.id),
              units_list: this.units_list.map(uc => ({unit: uc.unit, coefficient: uc.coefficient}))
            }

            if (this.a_1c_id)
              product_data._1c_update_required = true

            if (this.id) {
              let old_images_ids = this.getStockProductById(this.id).images.map(img => img.id)

              this
                  .actionPatchStockProduct({id: this.id, data: product_data})
                  .then(() => {
                    this
                        .actionUpdateStockProductCoefficients({
                          product_id: this.id,
                          coefficients: this.units_list
                        })
                        .then(() => this.storeImages(this.id, old_images_ids))
                        .catch(() => this.saving = false)
                  })
                  .catch(() => this.saving = false)
            } else {
              product_data.created_from = this.created_from

              this
                  .actionCreateStockProduct(product_data)
                  .then(p => {
                    this
                        .actionUpdateStockProductCoefficients({
                          product_id: p.id,
                          coefficients: this.units_list
                        })
                        .then(() => this.storeImages(p.id))
                        .catch(() => this.saving = false)
                  })
                  .catch(() => this.saving = false)
            }


          })
          .catch(() => this.saving = false)
    },

    /**
     * Шаг создания/сохранения 2: Сохранение изображений
     * .Удаление устаревших картинок
     * .Добавление новых
     * .Установка гл. изображения
     */
    storeImages(product_id, old_images_ids = []) {
      let to_delete = []

      if (this.id) {
        let new_images_ids = this.images.filter(img => img.id).map(img => img.id)

        to_delete = old_images_ids.filter(o => new_images_ids.indexOf(o) === -1)
      }

      Promise
          .all(to_delete.map(image_id => this.actionDeleteStockProductImage(image_id)))
          .then(() => {
            let upload_images_promises = this.images.map((image, idx) => {
              return new Promise((resolve, reject) => {
                if (image.id) {
                  resolve()
                } else {
                  this
                      .actionAddStockProductImage({
                        product: product_id,
                        image: image.url
                      })
                      .then((saved_img) => {
                        image.id = saved_img.id
                        image.url = process.env.VUE_APP_MEDIA_HOST + saved_img.url
                        resolve()
                      })
                      .catch(() => {
                        reject()
                      })
                }
              })
            })

            Promise
                .all(upload_images_promises)
                .then(() => {

                  new Promise((resolve => {
                    if (this.images.length > 0) {
                      stockProductsApi.setStockPrimaryProductImage(this.images[0].id).then(resolve)
                    } else {
                      resolve()
                    }
                  }))
                      .then(() => {
                        this
                            .actionFetchStockProductById(product_id)
                            .then(() => this.show = false)
                            .catch(() => this.saving = false)
                      })
                      .catch(() => this.saving = false)
                })
                .catch(() => this.saving = false)
          })
    },

    receiveCard(card) {
      this.saving = true

      this.name = card.name
      this.images = card.pictures.map(p => ({
        id: null,
        url: `data:image/jpeg;base64,${p}`
      }))
      this.price1 = card.price
      this.created_from = card.link
      this.description = card.description
      this.article = card.site_article

      let local_unit = this.$store.state.stock_units.stock_units.filter(u => {
        return (
            u.name.toLowerCase() === card.unit.toLowerCase()
            || u.name.toLowerCase() === card.unit.trim('.').toLowerCase()
        )
      })

      if (local_unit.length)
        this.unit = parseInt(local_unit[0].id)

      let features_checkings = []

      card.properties.map(({name, value}) => {
        features_checkings.push(
            this
                .actionCreateStockFeature({name})
                .then(feature => {
                  this.features.push({
                    feature: JSON.parse(JSON.stringify(feature)),
                    feature__search: null,
                    value
                  })
                })
        )
      })

      Promise
          .all(features_checkings)
          .then(() => this.saving = false)
    },
  },

  watch: {
    show(last, old) {
      if (!old && last) {
        this.saving = false
        this.slide = 0
        this.fetchProduct()
      }
    }
  }
}
</script>

<style lang="scss">
.carousel-images {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.carousel-image-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  margin-left: 8px;
  border: 2px solid transparent;
  border-bottom-color: #94949480;
  transition: border-color .2s linear;
  margin-top: 10px;

  &:first-child::before {
    content: "\FE6E";
    font-family: "Material Design Icons";
    color: #1976d2;
    font-size: 24px;
    position: relative;
    z-index: 1000;
    margin-bottom: -60px;
    text-shadow: 0px 0px 8px white;
    margin-left: -24px;
    left: 50px;
  }

  &.selected {
    border-color: #1976D2;
  }
}

.add-image-container {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>