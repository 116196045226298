<template>
  <div>
    <v-row
      v-if="isLoading"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col class="subtitle-1 text-center" cols="12">
        Загрузка...
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="indigo"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <router-view v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as catalogTypes from '@/common/catalogTypes'
import permissions from '@/common/permissions'
import { routes } from '@/router'

export default {
  props: {
    catalogType: String
  },
  created() {
    if (this.hasPermission) {
      this.actionFetchStockCategories()
      this.actionFetchStockTree()
      this.actionSelectCatalogType(this.catalogType || catalogTypes.structure)
    } else {
      this.$router.push({ name: routes.dashboard })
    }
  },
  watch: {
    $route(to) {
      this.actionSelectCatalogType(
        to.params.catalogType || catalogTypes.structure
      )
    }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUserHasPermission']),
    ...mapGetters('stock_categories', ['getStockCategoriesFetching']),
    ...mapGetters('stock_tree', ['getStockTreeFetching']),
    hasPermission: function() {
      return this.getCurrentUserHasPermission(permissions.appCatalog)
    },
    isLoading: function() {
      return this.getStockTreeFetching || this.getStockCategoriesFetching
    }
  },
  methods: {
    ...mapActions('app_catalog', ['actionSelectCatalogType']),
    ...mapActions('stock_categories', ['actionFetchStockCategories']),
    ...mapActions('stock_tree', ['actionFetchStockTree'])
  }
}
</script>

