<template>
  <v-breadcrumbs v-if="enabled" :items="items" class="pa-0" />
</template>

<script>
import { mapGetters } from 'vuex'
import * as catalogTypes from '@/common/catalogTypes'
import { routes } from '@/router'

export default {
  props: {
    catalogId: Number,
    productId: Number,
    removeLast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('app_catalog', ['activeCatalogType']),
    ...mapGetters('stock_categories', ['getStockCategoryAncestorsById']),
    ...mapGetters('stock_tree', ['getStockTreeAncestorsById']),
    items: function() {
      let ancestors = []
      switch (this.activeCatalogType) {
        case catalogTypes.structure:
          ancestors = this.getStockCategoryAncestorsById(this.catalogId)
          break
        case catalogTypes.category:
          ancestors = this.getStockTreeAncestorsById(this.catalogId)
          break
      }
      if (this.removeLast) {
        ancestors.shift()
      }
      const items = ancestors.map(item => ({
        text: item.name,
        exact: false,
        to: {
          name: routes.catalogDetail,
          params: { catalogType: this.activeCatalogType, catalogId: item.id }
        }
      }))
      items.push({
        text: 'Каталог',
        exact: true,
        to: {
          name: routes.catalogType,
          params: { catalogType: this.activeCatalogType }
        }
      })
      return items.reverse()
    },
    enabled: function() {
      return !!this.activeCatalogType
    }
  }
}
</script>
