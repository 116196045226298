<!-- Todo: Рассмотреть возможность объединить модуль с ./CatalogSelector.vue -->
<template>
  <v-dialog v-model="visible" max-width="600" scrollable>
    <template #activator="{on, attrs}">
      <v-text-field
          v-model="getBrandName"
          clearable
          label="Бренд"
          readonly
          v-on="on"
          v-bind="attrs"/>
    </template>
    <v-card :loading="getStockBrandsFetching">
      <v-card-title>
        Выберите бренд
      </v-card-title>
      <v-text-field class="mx-6" v-model="search" label="Поиск"/>
      <v-card-text>
        <v-treeview
            :items="getStockBrandsTree"
            :search="search"
            item-key="surrogate_id"
            class="py-3"
            hoverable
            open-on-click
            dense
        >
          <template #append="{ item }">
            <v-btn
                v-if="item.is_brand"
                @click="selectBrand(item.id)"
                small
                depressed
                color="primary"
            >
              Выбрать
            </v-btn>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BrandSelector",
  props: ['value'],
  data: () => ({
    visible: false,
    search: null,
  }),
  mounted() {
    if (this.$store.state.stock_brands.stock_brands.length === 0)
      this.actionFetchStockBrands()
  },
  computed: {
    ...mapGetters('stock_brands', [
      'getStockBrandById', 'getStockBrandCategoryById', 'getStockBrandsFetching', 'getStockBrandsTree'
    ]),

    getBrandName: {
      get() {
        if (this.value) {
          let brand = this.getStockBrandById(this.value)

          if (!brand)
            return null

          let category = this.getStockBrandCategoryById(brand.category)

          return `${category.name} / ${brand.name}`
        } else {
          return null
        }
      },
      set() {}
    }
  },
  methods: {
    ...mapActions('stock_brands', ['actionFetchStockBrands']),

    selectBrand(id) {
      this.visible = false
      this.$emit('input', id)
    }
  }
}
</script>

<style scoped>

</style>