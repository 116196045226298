<template>
  <v-dialog max-width="650" v-model="show" persistent>
    <template #activator="{ on, attrs }">
      <v-tooltip top>
        <template #activator="tooltip">
          <span v-on="tooltip.on" v-bind="tooltip.attrs">
            <v-btn
                class="mr-2 mt-2"
                outlined
                v-on="on"
                v-bind="attrs"
                v-text="`Коэф-ты (${ value.length })`"/>
          </span>
        </template>
        <span>
          Коэфиценты единиц измерения
        </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Коэфиценты единиц измерения</v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-data-table
              dense
              :headers="headers"
              :items="data"
              disable-pagination
              hide-default-footer
              disable-sort
          >
            <template #item.unit="{ item }">
              <v-autocomplete
                  :items="possible_units"
                  item-text="name"
                  :rules="[v => !!v || 'Единица измерения не указанна']"
                  item-value="id"
                  item-disabled="disabled"
                  v-model="item.unit"/>
            </template>
            <template #item.coefficient="{ item }">
              <v-text-field
                  v-model="item.coefficient"
                  type="number"
                  step="0.001"
                  :rules="[v => !!v || 'Коэффициент не указан']"
              />
            </template>
            <template #item.btn="{item}">
              <v-icon @click="data = data.filter(f => f !== item)" class="mx-n2">mdi-window-close</v-icon>
            </template>
            <template #footer>
              <v-btn
                  fab
                  x-small
                  @click="add"
                  color="primary"
                  class="mx-auto d-block"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn outlined :disabled="!valid" color="secondary" @click="close">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UnitCoefficientsDialog",
  props: {
    value: {
      default: [],
      type: Array
    }
  },

  data: () => ({
    valid: false,
    show: false,
    headers: [
      {
        text: 'Единица измерения',
        value: 'unit'
      },
      {
        text: 'Коэффициент',
        value: 'coefficient'
      },
      {
        value: 'btn',
      }
    ],
    data: []
  }),

  methods: {
    add() {
      if (this.data.filter(e => e.unit === null && e.coefficient === null).length === 0) {
        this.data.push({
          unit: null,
          coefficient: null
        })
      }

      this.$refs.form.validate()
    },

    close() {
      this.$emit('input', this.data)
      this.$nextTick(() => this.show = false)
    },
  },

  watch: {
    show(to) {
      if (to) {
        this.data = JSON.parse(JSON.stringify(this.value))
      }
    }
  },

  computed: {
    possible_units() {
      return this.$store.state.stock_units.stock_units
    }
  }
}
</script>

<style scoped>

</style>