<template>
  <v-row v-if="item" justify="start">
    <v-col cols="12">
      <Breadcrumbs :catalogId="catalogId" class="pa-0" />
    </v-col>
    <v-col cols="12">
      <EditableTitle
        :item="item"
        :editable="editable && isAdmin"
        :handler="actionPatchStockProduct"
      >
        <template #prepend>
          <div class="title">Товар:</div>
        </template>
      </EditableTitle>
      <v-row>
        <v-col cols="12" lg="4" xl="3">
          <v-popup-image title="Главное изображение товара" :src-original="photo" aspect-ratio="1" />
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <ProductEditorDialog :id="productId" edit-button-text="Редактировать"/>
            </v-col>
          </v-row>
          <v-row>
            <v-alert
                  v-if="hasUnverifiedPhoto"
                  type="info"
                  class="ma-0 mt-6"
                  dense
                  text
              >
                Главное изображение загружено и ожидает проверки
              </v-alert>
              <v-row v-if="!hasUnverifiedPhoto && hasImagesPermission">
                <v-col cols="12" class="pt-0">
                  <v-file-input
                      v-model="file"
                      accept="image/png, image/jpeg"
                      placeholder="Выберите файл"
                      prepend-icon="mdi-camera"
                      label="Изображение"
                      hide-details
                  ></v-file-input>
                </v-col>
                <v-col cols="12" align-self="center" class="pb-0">
                  <v-btn
                      @click.stop="uploadPhoto"
                      :disabled="!file"
                      color="primary"
                      block
                  >
                    Загрузить
                  </v-btn>
                </v-col>
              </v-row>
          </v-row>
        </v-col>

        <v-col>
          <v-row class="item-info py-3">
            <!-- stock_id -->
            <v-col cols="6"><span class="subtitle-2">Код:</span></v-col>
            <v-col cols="6" class="body-2">{{ item.id }}</v-col>

            <!-- 1c_id -->
            <v-col cols="6"><span class="subtitle-2">Код 1С:</span></v-col>
            <v-col cols="6" class="body-2">{{ item._1c_id }}</v-col>

            <!-- Артикул -->
            <v-col cols="6"><span class="subtitle-2">Артикул:</span></v-col>
            <v-col cols="6" class="body-2">{{ item.article || 'нет' }}</v-col>

            <!-- Вложенность -->
            <v-col cols="6"><span class="subtitle-2">Вложенность в коробке:</span></v-col>
            <v-col cols="6" class="body-2">{{ item.pieces_in_package || 'нет' }}</v-col>

            <!-- Вес упаковки -->
            <v-col cols="6"><span class="subtitle-2">Вес упаковки:</span></v-col>
            <v-col cols="6" class="body-2">{{ item.package_weight ? item.package_weight + ' кг' : 'нет' }}</v-col>

            <!-- Вес одной единицы товара -->
            <v-col cols="6"><span class="subtitle-2">Вес одной единицы товара:</span></v-col>
            <v-col cols="6" class="body-2">{{ item.piece_weight ? item.piece_weight + ' кг' : 'нет' }}</v-col>

            <!-- Объём одной единицы товара -->
            <v-col cols="6"><span class="subtitle-2">Объём одной единицы товара:</span></v-col>
            <v-col cols="6" class="body-2">{{ item.piece_volume ? item.piece_volume + ' м2' : 'нет' }}</v-col>

            <!-- structure -->
            <v-col cols="6" class="subtitle-2 text-truncate">
              Папка номенклатуры:
            </v-col>
            <v-col cols="6" class="body-2">
              <v-chip
                v-if="editable && isAdmin"
                @click="dialogTreeSelectDisplay(catalogTypes.structure, true)"
                close-icon="mdi-pencil"
                small
              >
                {{ structureName }}
              </v-chip>
              <router-link
                v-else-if="structure"
                :to="{
                  name: routes.catalogDetail,
                  params: {
                    catalogType: catalogTypes.structure,
                    catalogId: structure.id
                  }
                }"
              >
                {{ structureName }}
              </router-link>
            </v-col>
            <!-- category -->
            <v-col cols="6" class="subtitle-2 text-truncate">
              Ветка ассортимента:
            </v-col>
            <v-col cols="6" class="body-2">
              <v-chip
                v-if="editable && isAdmin"
                @click="dialogTreeSelectDisplay(catalogTypes.category, true)"
                close-icon="mdi-pencil"
                small
              >
                {{ categoryName }}
              </v-chip>
              <router-link
                v-else-if="category"
                :to="{
                  name: routes.catalogDetail,
                  params: {
                    catalogType: catalogTypes.category,
                    catalogId: category.id
                  }
                }"
              >
                {{ categoryName }}
              </router-link>
            </v-col>
            <!-- direction -->
            <v-col cols="6" class="subtitle-2 text-truncate">
              Товарное направление:
            </v-col>
            <v-col cols="6" class="body-2">
              <v-chip
                v-if="editable && isAdmin"
                @click="dialogTreeSelectDisplay(catalogTypes.direction, true)"
                close-icon="mdi-pencil"
                small
              >
                {{ directionName }}
              </v-chip>
              <div v-else>{{ directionName }}</div>
            </v-col>
            <!-- enabled -->
            <v-col cols="6">
              <span class="subtitle-2">
                Выгружать на сайт:
              </span>
            </v-col>
            <v-col cols="6">
              <v-switch
                @change="value => updateProduct({ enabled: value })"
                :input-value="item.enabled"
                :disabled="!editable || getStockProductsUpdating || !isAdmin"
                :loading="getStockProductsUpdating"
                class="mt-0 pt-0"
                hide-details
              ></v-switch>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col v-if="editable && isAdmin" cols="6">
              <a @click.stop="duplicateDialogOpen" class="dashed caption">
                Это дубль?
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-overlay
        color="white"
        :value="getStockProductsUpdating || getUploading"
        absolute
      >
        <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <!-- Папка номенклатуры -->
      <DialogTreeSelect
        :value="showDialogTreeSelect[catalogTypes.structure]"
        :items="getStockRootCategories"
        :itemSelectable="({ enabled }) => enabled"
        :active="[item.structure]"
        :open="dialogTreeSelectOpenStructure"
        @close="dialogTreeSelectDisplay(catalogTypes.structure, false)"
        @confirm="
          value => dialogTreeSelectSuccess(catalogTypes.structure, value)
        "
      >
        <template #title>Выберите папку номенклатуры</template>
        <template #label="{ item }">
          <span :class="!item.enabled && 'grey--text darken-3'">
            {{ item.name }}
          </span>
        </template>
      </DialogTreeSelect>
      <DialogConfirm
        :value="showDialogConfirm[catalogTypes.structure]"
        @close="dialogConfirmDisplay(catalogTypes.structure, false)"
        @confirm="dialogConfirmSuccess(catalogTypes.structure)"
      >
        <template #title>Подтвердите изменения</template>
        <template #text>
          <b>Папка номенклатуры</b>
          будет изменена, вы уверены?
        </template>
      </DialogConfirm>

      <!-- Ветка ассортимента -->
      <DialogTreeSelect
        :value="showDialogTreeSelect[catalogTypes.category]"
        :items="getStockRootTree"
        :itemSelectable="({ enabled }) => enabled"
        :active="[item.category]"
        :open="dialogTreeSelectOpenCategory"
        @close="dialogTreeSelectDisplay(catalogTypes.category, false)"
        @confirm="
          value => dialogTreeSelectSuccess(catalogTypes.category, value)
        "
      >
        <template #title>Выберите ветку ассортимента</template>
        <template #label="{ item }">
          <span :class="!item.enabled && 'grey--text darken-3'">
            {{ item.name }}
          </span>
        </template>
      </DialogTreeSelect>
      <DialogConfirm
        :value="showDialogConfirm[catalogTypes.category]"
        @close="dialogConfirmDisplay(catalogTypes.category, false)"
        @confirm="dialogConfirmSuccess(catalogTypes.category)"
      >
        <template #title>Подтвердите изменения</template>
        <template #text>
          <b>Ветка ассортимента</b>
          будет изменена, вы уверены?
        </template>
      </DialogConfirm>

      <!-- Товарное направление -->
      <DialogTreeSelect
        :value="showDialogTreeSelect[catalogTypes.direction]"
        :items="getStockDirectionsTreeRoot"
        :itemSelectable="({ selectable }) => selectable"
        :active="[item.direction]"
        :open="dialogTreeSelectOpenDirection"
        @close="dialogTreeSelectDisplay(catalogTypes.direction, false)"
        @confirm="
          value => dialogTreeSelectSuccess(catalogTypes.direction, value)
        "
      >
        <template #title>Выберите ветку ассортимента</template>
        <template #label="{ item }">{{ item.name }}</template>
      </DialogTreeSelect>
      <DialogConfirm
        :value="showDialogConfirm[catalogTypes.direction]"
        @close="dialogConfirmDisplay(catalogTypes.direction, false)"
        @confirm="dialogConfirmSuccess(catalogTypes.direction)"
      >
        <template #title>Подтвердите изменения</template>
        <template #text>
          <b>Товарное направление</b>
          будет изменено, вы уверены?
        </template>
      </DialogConfirm>

      <!-- Дубликат -->
      <v-dialog
        v-if="editable && isAdmin"
        :value="duplicate.dialogDisplay"
        width="600px"
        persistent
        scrollable
      >
        <v-card>
          <v-card-title>
            <span class="subtitle-1 font-weight-bold">
              Укажите идентификатор оригинального товара
            </span>
          </v-card-title>
          <v-card-text>
            <v-radio-group
              v-model="duplicate.identifierType"
              class="mt-2 mb-4"
              hide-details
            >
              <v-radio :value="identifierTypes._1c_id">
                <template #label>
                  <div>Код из 1С</div>
                </template>
              </v-radio>
              <v-radio :value="identifierTypes.id">
                <template #label>
                  <div>Код из агрегатора</div>
                </template>
              </v-radio>
            </v-radio-group>
            <v-text-field
              v-model="duplicate.identifierValue"
              label="Код товара"
              class="mt-2"
              hide-details
              outlined
              dense
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="px-6 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              @click.stop="duplicate.dialogDisplay = false"
              color="grey darken-1"
              outlined
            >
              Отмена
            </v-btn>
            <v-btn
              @click.stop="duplicateDialogSuccess"
              :disabled="!this.duplicate.identifierValue.trim().length"
              color="success"
              outlined
            >
              Продолжить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <DialogConfirm
        :value="duplicate.dialogConfirmDisplay"
        @close="duplicate.dialogConfirmDisplay = false"
        @confirm="duplicateDialogConfirmSuccess"
      >
        <template #title>Подтвердите изменения</template>
        <template #text>
          Данный товар будет удален. Вы уверены?
          <v-overlay
            color="white"
            :value="duplicate.requestInProgress"
            absolute
          >
            <v-progress-circular
              :size="50"
              color="indigo lighten-4"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
        </template>
      </DialogConfirm>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import permissions from '@/common/permissions'
import * as catalogTypes from '@/common/catalogTypes'
import { scrollToTarget } from '@/common/scroll'
import { routes } from '@/router'
import imagesApi from '@/services/imagesApi'
import Breadcrumbs from './Breadcrumbs'
import EditableTitle from './EditableTitle'
import DialogTreeSelect from './DialogTreeSelect'
import DialogConfirm from './DialogConfirm'
import ProductEditorDialog from '@/views/catalog/product-editor/ProductEditorDialog'

const identifierTypes = {
  id: 'id',
  _1c_id: '_1c_id'
}

export default {
  components: {
    Breadcrumbs,
    EditableTitle,
    DialogTreeSelect,
    DialogConfirm,
    ProductEditorDialog,
  },
  props: {
    productId: Number
  },
  data: () => ({
    file: null,
    hasUnverifiedPhoto: false,
    valuesDialogTreeSelect: {
      [catalogTypes.structure]: null,
      [catalogTypes.category]: null,
      [catalogTypes.direction]: null
    },
    // диалоги выбора
    showDialogTreeSelect: {
      [catalogTypes.structure]: false,
      [catalogTypes.category]: false,
      [catalogTypes.direction]: false
    },
    // диалоги подтверждения
    showDialogConfirm: {
      [catalogTypes.structure]: false,
      [catalogTypes.category]: false,
      [catalogTypes.direction]: false
    },
    // дубликат
    duplicate: {
      identifierType: identifierTypes._1c_id,
      identifierValue: '',
      dialogDisplay: false,
      dialogConfirmDisplay: false,
      requestInProgress: false
    }
  }),
  created() {
    this.catalogTypes = catalogTypes
    this.identifierTypes = identifierTypes
    this.routes = routes
    this.actionFetchStockProductById(this.productId)
    this.actionFetchStockDirections()
    if (this.hasImagesPermission) {
      this.fetchImagesUnverified()
    }
  },
  mounted() {
    scrollToTarget('#top-scroll-anchor')
  },
  watch: {
    productId: function() {
      this.actionFetchStockProductById(this.productId)
      if (this.hasImagesPermission) {
        this.fetchImagesUnverified()
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUserHasPermission']),
    ...mapGetters('app_catalog', ['activeCatalogType']),
    ...mapGetters('stock_products', [
      'getStockProductById',
      'getStockProductsFetching',
      'getStockProductsUpdating'
    ]),
    ...mapGetters('stock_categories', [
      'getStockCategoryById',
      'getStockCategoryAncestorsById'
    ]),
    ...mapGetters('stock_tree', [
      'getStockTreeById',
      'getStockRootTree',
      'getStockTreeAncestorsById'
    ]),
    ...mapGetters('stock_directions', [
      'getStockDirectionsById',
      'getStockDirectionsTreeRoot',
      'getStockDirectionsAncestorsById'
    ]),
    ...mapGetters('stock_categories', ['getStockRootCategories']),
    ...mapGetters('images', ['getUploading']),
    item: function() {
      return this.getStockProductById(this.productId)
    },
    photo: function() {
      return this.item && this.item.images && this.item.images.length
        ? this.item.images[0].url
        : ''
    },
    catalogId: function() {
      if (this.item) {
        return catalogTypes.structure === this.activeCatalogType
          ? this.item.structure
          : this.item.category
      }
      return null
    },
    editable: function() {
      return !this.item.created_from
    },
    hasImagesPermission: function() {
      return this.getCurrentUserHasPermission(permissions.appImages)
    },
    isAdmin: function() {
      return this.getCurrentUserHasPermission(permissions.appCatalogAdmin)
    },
    structure: function() {
      return this.item ? this.getStockCategoryById(this.item.structure) : null
    },
    structureName: function() {
      return (this.structure && this.structure.name) || '---'
    },
    category: function() {
      return this.item ? this.getStockTreeById(this.item.category) : null
    },
    categoryName: function() {
      return (this.category && this.category.name) || '---'
    },
    direction: function() {
      return this.item ? this.getStockDirectionsById(this.item.direction) : null
    },
    directionName: function() {
      return (this.direction && this.direction.name) || '---'
    },
    dialogTreeSelectOpenStructure: function() {
      return this.getStockCategoryAncestorsById(this.item.structure).map(
        item => item.id
      )
    },
    dialogTreeSelectOpenCategory: function() {
      return this.getStockTreeAncestorsById(this.item.category).map(
        item => item.id
      )
    },
    dialogTreeSelectOpenDirection: function() {
      return this.getStockDirectionsAncestorsById(this.item.direction).map(
        item => item.id
      )
    }
  },
  methods: {
    ...mapActions('stock_tree', ['actionFetchStockTreeById']),
    ...mapActions('stock_categories', ['actionFetchStockCategoryById']),
    ...mapActions('stock_products', [
      'actionFetchStockProductById',
      'actionFetchStockProductById1C',
      'actionPatchStockProduct',
      'actionDuplicateStockProduct'
    ]),
    ...mapActions('stock_directions', ['actionFetchStockDirections']),
    ...mapActions('images', ['actionUploadImage']),
    updateProduct: function(data) {
      return this.actionPatchStockProduct({
        id: this.item.id,
        data
      })
    },
    uploadPhoto: function() {
      this.actionUploadImage({
        type: catalogTypes.product,
        id: this.item.id,
        file: this.file
      }).then(() => {
        this.file = null
        this.fetchImagesUnverified()
      })
    },
    fetchImagesUnverified: function() {
      this.hasUnverifiedPhoto = false
      imagesApi
        .getModerate(catalogTypes.product, {
          filterByTarget: this.productId,
          filterUnverified: true
        })
        .then(data => {
          this.hasUnverifiedPhoto = data.results.filter(r => e.target.id == this.productId).length > 0
        })
    },
    dialogConfirmDisplay: function(type, value) {
      this.showDialogConfirm = {
        ...this.showDialogConfirm,
        [type]: value
      }
    },
    dialogConfirmSuccess: function(type) {
      this.updateProduct({ [type]: this.valuesDialogTreeSelect[type] }).then(
        () => {
          this.showDialogConfirm[type] = false
        }
      )
    },
    dialogTreeSelectSuccess: function(type, value) {
      this.valuesDialogTreeSelect[type] = value
      this.showDialogTreeSelect[type] = false
      this.showDialogConfirm[type] = true
    },
    dialogTreeSelectDisplay: function(type, value) {
      this.showDialogTreeSelect = {
        ...this.showDialogTreeSelect,
        [type]: value
      }
    },
    duplicateDialogOpen: function() {
      this.duplicate = {
        ...this.duplicate,
        identifierType: identifierTypes._1c_id,
        identifierValue: '',
        dialogDisplay: true,
        requestInProgress: false
      }
    },
    duplicateDialogSuccess: function() {
      if (!this.duplicate.identifierValue.trim().length) return
      this.duplicate.dialogDisplay = false
      this.duplicate.dialogConfirmDisplay = true
    },
    duplicateDialogConfirmSuccess: function() {
      this.duplicate.requestInProgress = true
      const fetchProductMethod =
        this.duplicate.identifierType === identifierTypes.id
          ? this.actionFetchStockProductById
          : this.actionFetchStockProductById1C
      fetchProductMethod(this.duplicate.identifierValue.trim()).then(
        targetItemData => {
          this.actionDuplicateStockProduct({
            id: this.item.id,
            target_id: targetItemData.id
          })
            .then(() => {
              const catalogId =
                this.activeCatalogType === catalogTypes.structure
                  ? this.item.structure
                  : this.item.category
              const catalogFetchMethod =
                this.activeCatalogType === catalogTypes.structure
                  ? this.actionFetchStockCategoryById
                  : this.actionFetchStockTreeById
              catalogFetchMethod(catalogId).then(() => {
                this.duplicate.dialogConfirmDisplay = false
                this.duplicate.requestInProgress = false
                this.$router.push({
                  name: routes.catalogDetail,
                  params: {
                    catalogType: this.activeCatalogType,
                    catalogId
                  }
                })
              })
            })
            .catch(() => {
              this.duplicate.dialogConfirmDisplay = false
              this.duplicate.requestInProgress = false
            })
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.item-container {
  position: relative;
}
.item-info {
  > .col {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
.export-form {
  position: relative;
}
</style>
