<template>
  <v-hover #default="{ hover }">
    <v-card
      class="catalog-item"
      :to="link"
      :elevation="hover ? 12 : 2"
      height="100%"
    >
      <div class="d-flex">
        <v-catalog-img class="item-image" aspect-ratio="1.5" :src="photo" />
        <div class="d-flex flex-column item-info">
          <v-tooltip color="black" left>
            <template #activator="{ on }">
              <v-icon class="mt-2" :color="iconColor1C" v-on="on">
                mdi-link-box-variant
              </v-icon>
            </template>
            {{ tooltipText1C }}
          </v-tooltip>
          <v-tooltip color="black" left>
            <template #activator="{ on }">
              <v-icon class="mt-3" :color="iconColorShop" v-on="on">
                mdi-shopping
              </v-icon>
            </template>
            {{ tooltipTextShop }}
          </v-tooltip>
        </div>
      </div>
      <v-card-text :title="item.name">
        {{ item.name }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import { catalogItemMixin } from './mixins'
import { routes } from '@/router'

export default {
  mixins: [catalogItemMixin],
  props: {
    item: Object,
    activeCatalogType: String
  },
  computed: {
    photo: function() {
      return this.item.preview || ''
    },
    link: function() {
      return {
        name: routes.catalogDetail,
        params: { catalogType: this.activeCatalogType, catalogId: this.item.id }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.catalog-item {
  .item-image {
    border-top-left-radius: inherit;
  }
  .item-info {
    max-width: 40px;
    width: 40px;
    flex: 0 0 40px;
  }
}
</style>
