<template>
  <div>
    <v-list dense>
      <v-list-item
        v-for="item in menuItems"
        :key="item.key"
        :to="{ name: item.link }"
        :exact="item.exact"
        link
        dense
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider class="mt-3 mb-6"></v-divider>
  </div>
</template>

<script>
import { routes } from '@/router'

export default {
  data: () => ({
    menuItems: [
      {
        key: routes.catalogIndex,
        title: 'Главная',
        icon: 'mdi-monitor-dashboard',
        link: routes.catalogIndex,
        exact: true
      }
    ]
  })
}
</script>
