<template>
  <div class="aside-content">
    <v-divider class="mt-3 mb-6"></v-divider>
    <v-overlay color="white" class="py-12" :value="catalogIsLoading" absolute>
      <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-tooltip right>
      <template #activator="{ on, attrs }">
        <span v-on="on" v-bind="attrs">
          <v-checkbox v-model="filterImportedGoods" label="Созданные по ссылке"/>
        </span>
      </template>
      <span>
        Выборка только тех товаров, которые были  <br>
        созданны по ссылке на основе карточек товаров <br>
        с других сайтов вручную
      </span>
    </v-tooltip>
    <div v-if="!catalogIsLoading">
      <!-- Переключение типа каталога -->
      <div class="subtitle-1 font-weight-light">Каталог</div>
      <v-radio-group
          v-model="readioCatalogType"
          class="mt-1"
          hide-details
          dense
      >
        <v-radio :key="catalogTypes.structure" :value="catalogTypes.structure">
          <template #label>
            <span class="body-2">Дерево номенклатуры</span>
          </template>
        </v-radio>
        <v-radio :key="catalogTypes.category" :value="catalogTypes.category">
          <template #label>
            <span class="body-2">Дерево ассортимента</span>
          </template>
        </v-radio>
      </v-radio-group>

      <!-- Дерево каталога -->
      <v-treeview
          v-if="catalogItems.length"
          :items="catalogItems"
          class="catalog py-0 mt-3"
          hoverable
          open-on-click
          dense
          return-object
      >
        <template #append="{ item }">
          <v-tooltip bottom v-if="getFilterImportedGoods && item.child_with_imported_goods">
            <template #activator="{ on, attrs }">
              <v-icon
                  x-small
                  v-on="on"
                  v-bind="attrs"
                  color="green"
              >mdi-checkbox-blank-circle</v-icon>
            </template>
            <span>
              Содержит субкатегории с<br> импортированными товарами
            </span>
          </v-tooltip>

          <v-tooltip bottom v-if="getFilterImportedGoods && item.with_imported_goods">
            <template #activator="{ on, attrs }">
              <v-icon
                  x-small
                  v-on="on"
                  v-bind="attrs"
                  color="orange"
              >mdi-checkbox-blank-circle</v-icon>
            </template>
            <span>
              Содержит импортированные товары
            </span>
          </v-tooltip>



          <v-btn @click.stop="goTo(item)" color="blue darken-1" small icon>
            <v-icon>mdi-arrow-right-bold-box</v-icon>
          </v-btn>
        </template>
        <template #label="{ item }">
          <span :class="!item.enabled && 'grey--text darken-3'">
            {{ item.name }}
          </span>
        </template>
      </v-treeview>
      <div v-else class="body-2 font-weight-light text-center pt-3">
        Нет элементов
      </div>

      <v-divider class="mt-4"></v-divider>

      <expand-menu></expand-menu>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {routes} from '@/router'
import * as catalogTypes from '@/common/catalogTypes'
import {scrollToTarget} from '@/common/scroll'
import {catalogListMixin} from './mixins'
import ExpandMenu from "@/components/ExpandMenu.vue";


export default {
  mixins: [catalogListMixin],
  components: {ExpandMenu},
  data: () => ({
    readioCatalogType: catalogTypes.structure,
  }),
  created() {
    this.catalogTypes = catalogTypes
  },
  watch: {
    // отслеживаем изменени радио и меняем урл
    readioCatalogType: function (type) {
      if (this.activeCatalogType !== type) {
        this.$router.push({
          name: routes.catalogType,
          params: {catalogType: type}
        })
      }
    },
    // отслеживаем изменения урла и меняем радио
    activeCatalogType: function (type) {
      this.readioCatalogType = type
    }
  },
  computed: {
    ...mapGetters('app_catalog', ['getLeftPanelExpanded', 'getFilterImportedGoods']),

    filterImportedGoods: {
      get() {
        return this.$store.state.app_catalog.filter_imported_goods
      },
      set() {
        this.actionToggleImportedGoods()
      }
    },
  },
  methods: {
    ...mapActions('app_catalog', ['actionSwitchLeftPanelExpansion', 'actionToggleImportedGoods']),

    goTo: function (item) {
      this.$router
          .push({
            name: routes.catalogDetail,
            params: {catalogType: this.activeCatalogType, catalogId: item.id}
          })
          .catch(() => {
          })
      scrollToTarget('#top-scroll-anchor')
    },
  }
}
</script>

<style scoped lang="scss">
.aside-content {
  position: relative;

  .catalog {
    font-size: 0.85rem;
  }
}
</style>
