<template>
  <div
    class="d-flex flex-md-row flex-column align-md-center align-stretch title-container"
  >
    <slot name="prepend"></slot>
    <div
      v-if="formOpened"
      class="d-flex align-md-center align-stretch flex-grow-1 flex-md-row flex-column pl-md-2"
    >
      <v-text-field
        v-model="name"
        label="Название"
        class="mt-md-0 mt-1"
        hide-details
        outlined
        dense
      ></v-text-field>
      <v-btn
        @click.stop="closeForm"
        class="ml-md-1 mt-md-0 mt-1"
        depressed
        outlined
      >
        Отменить
      </v-btn>
      <v-btn
        @click.stop="request"
        :disabled="!isValid()"
        class="ml-md-1 mt-md-0 mt-1"
        color="primary"
        depressed
        outlined
      >
        Сохранить
      </v-btn>
    </div>
    <div
      v-else
      class="d-flex align-md-center align-stretch flex-grow-1 pl-md-2"
    >
      <div class="flex-grow-1 subtitle-1">
        {{ item.name }}
      </div>
      <v-btn
        v-if="editable"
        @click.stop="openForm"
        class="ml-2"
        color="indigo darken-1"
        small
        depressed
        icon
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    handler: Function,
    editable: Boolean
  },
  created() {
    this.name = this.item.name
  },
  data: () => ({
    formOpened: false,
    name: ''
  }),
  watch: {
    item: function(newItem) {
      this.name = newItem.name
    }
  },
  methods: {
    openForm: function() {
      this.formOpened = true
    },
    closeForm: function() {
      this.name = this.item.name
      this.formOpened = false
    },
    isValid: function() {
      const trimedName = this.name.trim()
      return trimedName.length > 0 && trimedName !== this.item.name
    },
    request: function() {
      if (this.isValid()) {
        this.handler({
          id: this.item.id,
          data: { name: this.name }
        }).then(() => {
          this.name = this.item.name
          this.closeForm()
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title-container {
  min-height: 40px;
  position: relative;
}
</style>
