export default {
  computed: {
    iconColor1C: function() {
      return this.item._1c_id ? 'success' : 'grey darken-1'
    },
    iconColorShop: function() {
      return this.item.enabled ? 'success' : 'grey darken-1'
    },
    tooltipText1C: function() {
      return this.item._1c_id ? `Код 1С: ${this.item._1c_id}` : 'Не создан в 1С'
    },
    tooltipTextShop: function() {
      return this.item.enabled
        ? 'Выгружается в магазины'
        : 'Не выгружается в магазины'
    }
  }
}
