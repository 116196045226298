import Vuetify from '@/plugins/vuetify'

export default (target, inputOptions = {}) => {
  const doc = document.documentElement
  const options = {
    offset: Math.ceil(doc.clientHeight / 3),
    ...inputOptions
  }
  let timerId = setInterval(() => {
    const el = document.querySelector(target)
    if (el) {
      Vuetify.framework.goTo(el, options)
      clearInterval(timerId)
    }
  }, 100)
}
